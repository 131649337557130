import { Injectable } from '@angular/core';
import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';
import { switchMap } from 'rxjs';

import { selectDocumentTemplateFn } from '~/shared/modal/document/select-template/select-document-template';

import { DocumentTemplateType } from '../documents/document-template.type';

@Injectable({ providedIn: 'root' })
export class NotificationsApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'notifications',
  })
  protected readonly endpoint!: CrmEndpoint<unknown>;

  private selectTemplate = selectDocumentTemplateFn();

  sendMail<Body>(body: Body) {
    return this.endpoint.create(body, {
      params: { type: 'email', template: 'blank' },
    });
  }

  batchSegment<Body, Params>(id: string, body: Body, params: Params) {
    return this.endpoint.request('POST', ['segment', id].join('/'), {
      body,
      params,
    });
  }

  sendSms<Body>(body: Body) {
    return this.endpoint.create(body, {
      params: { type: 'sms', template: 'blank' },
    });
  }

  sendInvoice<Body>(id: string, body: Body) {
    return this.selectAndSend('invoice', id, body);
  }

  sendTimeline<Body>(id: string, body: Body) {
    return this.selectAndSend('timeline', id, body);
  }

  sendRecord<Body>(id: string, body: Body) {
    return this.selectAndSend('record', id, body);
  }

  sendDocument<Body>(path: string, body: Body) {
    return this.endpoint.request('POST', ['documents', path].join('/'), {
      params: { template: 'blank' },
      body,
    });
  }

  private selectAndSend<Body>(
    type: DocumentTemplateType,
    id: string,
    body: Body,
  ) {
    return this.selectTemplate(type).pipe(
      switchMap((template) => {
        return this.sendDocument([type, id, template._id].join('/'), body);
      }),
    );
  }
}
